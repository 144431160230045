import React, { useState } from 'react';
import LegalAgreement from './LegalAgreement';
import docConfig from '../LegalText/doc-config';
import queryString from 'query-string';

const App = () => {
    const [enableButton, setEnableButton] = useState(false);
    const checkBoxesHTML = [];
    const uid = queryString.parse(window.location.search).uid;
    const approvalConfig = docConfig[uid];

    const EnableSubmit = (event) => {
        const textinputs = document.querySelectorAll('input[type=checkbox]');
        const foundChecked = [].filter.call(textinputs, (el) => {
            return el.checked;
        });

        if (textinputs.length === foundChecked.length) {
            setEnableButton(true);
        } else {
            setEnableButton(false);
        }
    }

    return (
        <div className="ui" >
            <div className="">
                <img src="/images/wtiLogo.png"
                    alt="World Travel Inc. Logo"
                    className="ui large centered image" />

            </div>
            {
                approvalConfig && approvalConfig.documents.map(
                    (document) => {
                        checkBoxesHTML.push(
                            <div className="ui left aligned container" key={document.fileName}>
                                <div className="ui checkbox" key={document.fileName} onClick={EnableSubmit}>
                                    <input
                                        id={document.fileName}
                                        type="checkbox"
                                        className="hidden" />
                                    <label htmlFor={document.fileName}>
                                        I agree to the {document.title}.
                                    </label>
                                </div>
                            </div>
                        );

                        return (
                            <LegalAgreement
                                key={document.fileName}
                                title={document.title}
                                fileName={document.fileName} />
                        );
                    })
            }
            {checkBoxesHTML}

            <div className="ui hidden divider" />

            <div className="ui container">
                <center> {
                    enableButton ?
                        <button className="ui button blue"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href = approvalConfig.destination;
                            }}
                            id="button">
                            Continue
                        </button> :
                        <button className="ui button blue"
                            type="button"
                            id="button"
                            disabled>
                            Continue
                        </button>
                }
                </center>
            </div>
            <div className="ui hidden divider" />
        </div>
    );
}

export default App;