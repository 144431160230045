import React from 'react';
import '../styles/index.css';

const LegalAgreement = ({ fileName, title }) => {
    if (!fileName) {
        return <div>Loading...</div>;
    }

    return (
        <div key={title} className="ui" >
            <h1 className="ui center aligned header">{title}</h1>

            <iframe
                key={title}
                title={title}
                className="ui container tcIframe"
                src={`/legal/${fileName}#toolbar=0&navpanes=0`}
            />

            <div className="ui section divider" />
        </div>
    )
}

export default LegalAgreement;